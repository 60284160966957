import React, { useContext, useEffect } from "react"
import { ContextDispatch } from "./../../context/Context"

//Components
import Accordion from "../../components/accordion/accordion"
import Footer from "../../components/footer/footer"
import Carousel from "../../components/carousel/carousel"
import TextModal from "../../components/text-modal/text-modal"
import Hero from "../../components/large-hero/large-hero-2"
import NextPage from "../../components/next-page/next-page"
import SEO from "../../components/seo/seo"
import Platform from "../../components/platform/platform"
import DriveUs from "../../components/drive-us/drive-us"
import KeyPeices from "../../components/key-peices/key-peices"
import BenefitsAdvantages from "../../components/benefits-advantages/benefits-advantages"

// import pageTemplateQuery from "./pageQuery"

const PageTemplate1 = props => {
  const page = props,
    templateData = page.data.contentfulTemplate1

  console.log("test")
  console.log(templateData)

  const storeDispatch = useContext(ContextDispatch)

  useEffect(() => {
    const header = () => {
      storeDispatch({ type: "HEADER_DARK" })
    }

    header()
  }, [storeDispatch])

  const heroData = {
    heroImage: templateData?.heroImage,
    heroTitle: templateData?.heroTitle,
    heroText: templateData?.heroText,
  }

  let modalblock,
    platformblock,
    carouselblock,
    keypeices,
    driveus,
    nextpageblock

  if (templateData.textCarouselModalMainCopy.textCarouselModalMainCopy) {
    modalblock = <TextModal buttonTheme={`dark`} sectionData={templateData} />
  }

  if (templateData.accordionTitle) {
    platformblock = <Platform sectionData={templateData} />
  }

  if (templateData.carousel) {
    keypeices = <KeyPeices sectionData={templateData.carousel} />
  }

  if (templateData.ourValuesTitle) {
    driveus = <DriveUs sectionData={templateData} />
  }

  if (templateData.nextPage) {
    nextpageblock = (
      <NextPage
        heading={templateData.nextPage.heading}
        title={templateData.nextPage.linkTitle}
        url={`/` + templateData.nextPage.page.slug}
      />
    )
  }

  if (templateData.carousel?.carouselTitle) {
    carouselblock = (
      <Carousel
        bgColour={`#172138`}
        title={templateData.carousel.carouselTitle}
        mainImg={templateData.carousel.carouselMainImage}
        vimeo={templateData.carousel.carouselVimeo}
        items={templateData.carousel}
      />
    )
  }

  return (
    <React.Fragment>
      <SEO
        title={templateData.seoData.seoTitle}
        image={templateData.seoData.seoImage}
        description={templateData.seoData.seoDescription}
      />
      <Hero heroData={heroData} />
      {/* {modalblock} */}
      <BenefitsAdvantages
        col1={templateData.scaledPlatformBenefits}
        col2={templateData.craftsmanLikeModelBenefits}
        col3={templateData.ourAdvantage}
      />
      {templateData.showAccordion && platformblock}
      {/* {carouselblock} */}
      {keypeices}
      {driveus}
      {nextpageblock}

      <Footer />
    </React.Fragment>
  )
}

export default PageTemplate1

export const pageQuery = graphql`
  query template1NewPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulTemplate1(slug: { eq: $slug }) {
      ...template1PageQuery
    }
  }
`
