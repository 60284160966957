import React from "react"

// InView detector
import { InView } from "react-intersection-observer"

// Component
import ScrollSection from "../locomotiveScroll/scrollSection"
import SplitHeading from "../split-big-heading/split-big-heading"
import JsonText from "../_jsonText"

// SVG
import Logo from "./../../../static/images/svg/logo.inline.svg"

// Styles
import styles from "./benefits-advantages.module.scss"

const BenefitsAdvantages = props => {
  const { col1, col2, col3 } = props

  return (
    <ScrollSection>
      <div className={styles.mainWrapper}>
        <InView triggerOnce={true} threshold={0.2}>
          {({ inView, ref, entry }) => (
            <div
              className={`container ${styles.container}`}
              ref={ref}
              inview={inView.toString()}
            >
              <div className={`cols flex ${styles.cols}`}>
                <div className={styles.benefitsDiagram}>
                  <div className={styles.benefitsIcon}>
                    <svg
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 3.33325V12.6666"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.33398 8H12.6673"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className={styles.benefitsIcon}>
                    <svg
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.33331 10H12.6666"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.33331 6H12.6666"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>

                {col1.length > 0 && (
                  <div className={`col m10 t4 d4 ${styles.benefitsCol}`}>
                    <SplitHeading
                      classString={`copy copy--m ${styles.heading}`}
                    >
                      Scaled Platform
                    </SplitHeading>

                    <div className={styles.benefitsWrapper}>
                      <p>Benefits</p>
                      <ul className={styles.benefitsList}>
                        {col1.map(line => (
                          <li className={styles.benefitsCard}>
                            {line && (
                              <p className={styles.benefitItem}>{line}</p>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                {col2.length > 0 && (
                  <div className={`col m10 t4 d4 ${styles.benefitsCol}`}>
                    <SplitHeading
                      classString={`copy copy--m ${styles.heading}`}
                    >
                      Craftsman-Like Model
                    </SplitHeading>

                    <div className={styles.benefitsWrapper}>
                      <p>Benefits</p>
                      <ul className={styles.benefitsList}>
                        {col2.map(line => (
                          <li className={styles.benefitsCard}>
                            {line && (
                              <p className={styles.benefitItem}>{line}</p>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                {col3.length > 0 && (
                  <div className={`col m10 t4 d4 ${styles.benefitsCol}`}>
                    <SplitHeading
                      classString={`copy copy--m ${styles.heading}`}
                    >
                      Our Advantage
                    </SplitHeading>

                    <div className={styles.benefitsWrapper}>
                      <p>
                        <Logo />
                      </p>
                      <ul className={styles.advantagesList}>
                        {col3.map((line, lineIndex) => (
                          <li
                            className={styles.benefitsCard}
                            data-index={lineIndex + 1}
                          >
                            {line && (
                              <p className={styles.benefitItem}>{line}</p>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </InView>
      </div>
    </ScrollSection>
  )
}

export default BenefitsAdvantages
